import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
  HashRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import './App.css';
import Edit from './pages/Edit';

import RateFinder from './pages/RateFinder';
import { fetchData } from './store/actions/data';
import { validateToken } from './store/actions/user';
function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(validateToken())
    dispatch(fetchData())
  }, [])
  const { loggedIn } = useSelector(state => state.user);
  console.log(loggedIn)
  return (
    <Router>
      {loggedIn ? <Routes>
          <Route exact path="/" element={<RateFinder />} />
          <Route path="/edit" element={<Edit />} />
      </Routes> : <Routes>
          <Route path="/" element={<RateFinder />} />
      </Routes>}
      
    </Router>
      
  );
}

export default App;
