export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REDIRECT_PATH = 'AUTH_REDIRECT_PATH';

export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'
export const LOG_IN = 'LOG_IN'
export const LOG_OUT = 'LOG_OUT'

export const FETCH_DATA_START = 'FETCH_DATA_START'
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS'
export const FETCH_DATA_FAILED = 'FETCH_DATA_FAILED'

export const DELETE_RATE = 'DELETE_RATE'
export const UPDATE_RATE = 'UPDATE_RATE'
export const CREATE_RATE = 'CREATE_RATE'