import React from 'react';
import API from '../../utils/api';
import * as actionTypes from './actionTypes';

const login = () => {
    return { type: actionTypes.LOG_IN };
  };
  
  const logout = () => {
    return { type: actionTypes.LOG_OUT };
  };

  const setHeaders = (headers) => {
      localStorage.setItem('access-token', headers['access-token'])
      localStorage.setItem('expire-at', headers['expire-at'])
      localStorage.setItem('refresh-token', headers['refresh-token'])
  }
  const deleteHeaders = () => {

    localStorage.removeItem('access-token')
    localStorage.removeItem('expire-at')
    localStorage.removeItem('refresh-token')
}
export const handleLogout = () => {
    return dispatch => {
      API.delete('api/users/sign_out')
        .then(res => {
          console.log(res)
          deleteHeaders();
          dispatch(logout());
        })
        .catch(res => {
          console.log(res)
        });
    };
  };
  
  export const handleLogin = ({email, password }) => {
    return dispatch => {
      API.post('api/users/sign_in', { email, password })
        .then(res => {
          const { data: { status, message }, headers } = res;
          console.log(res)
          setHeaders(headers);
          dispatch(login(true));
        })
        .catch(res => {
          console.log(res)
          // const messages = res.response.data.errors ? res.response.data.errors.map((message, idx) =>
          //     <div key={idx}>{message}</div>) : null;
          // const { headers } = res;
        });
    };
  };
  
  export const validateToken = () => {
    const expireAt = localStorage.getItem('expire-at');

    // if (expireAt < Date.now()) {
    //   return dispatch => {
    //     deleteHeaders();
    //     dispatch(logout());
    //   };
    // }
    return dispatch => {
      dispatch({ type: actionTypes.VALIDATE_TOKEN });
      API.post('api/users/tokens')
        .then(res => {
          const { data: { status, message }, headers } = res;
          setHeaders(headers);
          dispatch(login(true));
        })
        .catch(res => {
          deleteHeaders();
          dispatch(logout());
        });
    };
  };