function Rate(city, transportType, minWeight, maxWeight, boardingCost, insurance, byWeigth, price) {
    this.city = city;
    this.transportType = transportType;
    this.minWeight = minWeight;
    this.maxWeight = maxWeight;
    this.boardingCost = boardingCost;
    this.insurance = insurance;
    this.byWeigth = byWeigth
    this.price = price;
  }

const rates = [
    // AIR
    new Rate('Arica', 'Air', 0, 10, 0, 0, false, 23793),
    new Rate('Arica', 'Air', 11, 20, 0, 0, true, 2541),
    new Rate('Arica', 'Air', 21, 30, 0, 0, true, 1676),
    new Rate('Arica', 'Air', 31, 40, 0, 0, true, 1676),
    new Rate('Arica', 'Air', 41, 50, 0, 0, true, 1676),
    new Rate('Arica', 'Air', 51, 100, 0, 0, true, 1651),
    new Rate('Arica', 'Air', 101, 300, 0, 0, true, 1599),
    new Rate('Arica', 'Air', 301, 500, 0, 0, true, 1599),
    new Rate('Arica', 'Air', 501, Number.POSITIVE_INFINITY, 0, 0, true, 1599),

    new Rate('Iquique', 'Air', 0, 10, 0, 0, false, 23793),
    new Rate('Iquique', 'Air', 11, 20, 0, 0, true, 2541),
    new Rate('Iquique', 'Air', 21, 30, 0, 0, true, 1676),
    new Rate('Iquique', 'Air', 31, 40, 0, 0, true, 1676),
    new Rate('Iquique', 'Air', 41, 50, 0, 0, true, 1676),
    new Rate('Iquique', 'Air', 51, 100, 0, 0, true, 1512),
    new Rate('Iquique', 'Air', 101, 300, 0, 0, true, 1447),
    new Rate('Iquique', 'Air', 301, 500, 0, 0, true, 1447),
    new Rate('Iquique', 'Air', 501, Number.POSITIVE_INFINITY, 0, 0, true, 1447),

    new Rate('Antofagasta', 'Air', 0, 10, 0, 0, false, 23793),
    new Rate('Antofagasta', 'Air', 11, 20, 0, 0, true, 2541),
    new Rate('Antofagasta', 'Air', 21, 30, 0, 0, true, 1676),
    new Rate('Antofagasta', 'Air', 31, 40, 0, 0, true, 1676),
    new Rate('Antofagasta', 'Air', 41, 50, 0, 0, true, 1676),
    new Rate('Antofagasta', 'Air', 51, 100, 0, 0, true, 1363),
    new Rate('Antofagasta', 'Air', 101, 300, 0, 0, true, 1308),
    new Rate('Antofagasta', 'Air', 301, 500, 0, 0, true, 1308),
    new Rate('Antofagasta', 'Air', 501, Number.POSITIVE_INFINITY, 0, 0, true, 1308),

    new Rate('Calama', 'Air', 0, 10, 0, 0, false, 23793),
    new Rate('Calama', 'Air', 11, 20, 0, 0, true, 2541),
    new Rate('Calama', 'Air', 21, 30, 0, 0, true, 1676),
    new Rate('Calama', 'Air', 31, 40, 0, 0, true, 1676),
    new Rate('Calama', 'Air', 41, 50, 0, 0, true, 1676),
    new Rate('Calama', 'Air', 51, 100, 0, 0, true, 1363),
    new Rate('Calama', 'Air', 101, 300, 0, 0, true, 1308),
    new Rate('Calama', 'Air', 301, 500, 0, 0, true, 1308),
    new Rate('Calama', 'Air', 501, Number.POSITIVE_INFINITY, 0, 0, true, 1308),

    new Rate('Puerto Montt', 'Air', 0, 10, 0, 0, false, 35500),
    new Rate('Puerto Montt', 'Air', 11, 20, 0, 0, true, 3963),
    new Rate('Puerto Montt', 'Air', 21, 30, 0, 0, true, 2724),
    new Rate('Puerto Montt', 'Air', 31, 40, 0, 0, true, 2724),
    new Rate('Puerto Montt', 'Air', 41, 50, 0, 0, true, 2724),
    new Rate('Puerto Montt', 'Air', 51, 100, 0, 0, true, 1663),
    new Rate('Puerto Montt', 'Air', 101, 300, 0, 0, true, 1290),
    new Rate('Puerto Montt', 'Air', 301, 500, 0, 0, true, 1165),
    new Rate('Puerto Montt', 'Air', 501, Number.POSITIVE_INFINITY, 0, 0, true, 1140),

    new Rate('Balmaceda', 'Air', 0, 10, 0, 0, false, 64156),
    new Rate('Balmaceda', 'Air', 11, 20, 0, 0, true, 4233),
    new Rate('Balmaceda', 'Air', 21, 30, 0, 0, true, 3765),
    new Rate('Balmaceda', 'Air', 31, 40, 0, 0, true, 3765),
    new Rate('Balmaceda', 'Air', 41, 50, 0, 0, true, 3765),
    new Rate('Balmaceda', 'Air', 51, 100, 0, 0, true, 3302),
    new Rate('Balmaceda', 'Air', 101, 300, 0, 0, true, 2406),
    new Rate('Balmaceda', 'Air', 301, 500, 0, 0, true, 2173),
    new Rate('Balmaceda', 'Air', 501, Number.POSITIVE_INFINITY, 0, 0, true, 2132),

    new Rate('Punta Arenas', 'Air', 0, 10, 0, 0, false, 39067),
    new Rate('Punta Arenas', 'Air', 11, 20, 0, 0, true, 4605),
    new Rate('Punta Arenas', 'Air', 21, 30, 0, 0, true, 4044),
    new Rate('Punta Arenas', 'Air', 31, 40, 0, 0, true, 4044),
    new Rate('Punta Arenas', 'Air', 41, 50, 0, 0, true, 4044),
    new Rate('Punta Arenas', 'Air', 51, 100, 0, 0, true, 3160),
    new Rate('Punta Arenas', 'Air', 101, 300, 0, 0, true, 2914),
    new Rate('Punta Arenas', 'Air', 301, 500, 0, 0, true, 2783),
    new Rate('Punta Arenas', 'Air', 501, Number.POSITIVE_INFINITY, 0, 0, true, 2614),

    // GROUND
    new Rate('Antofagasta', 'Ground', 0, 5, 0, 0, false, 21793),
    new Rate('Antofagasta', 'Ground', 6, 10, 0, 0, true, 2584),
    new Rate('Antofagasta', 'Ground', 11, 20, 0, 0, true, 1806),
    new Rate('Antofagasta', 'Ground', 21, 30, 0, 0, true, 1382),
    new Rate('Antofagasta', 'Ground', 31, 40, 0, 0, true, 1171),
    new Rate('Antofagasta', 'Ground', 41, 50, 0, 0, true, 908),
    new Rate('Antofagasta', 'Ground', 51, 60, 0, 0, true, 844),
    new Rate('Antofagasta', 'Ground', 61, 70, 0, 0, true, 776),
    new Rate('Antofagasta', 'Ground', 71, 80, 0, 0, true, 755),
    new Rate('Antofagasta', 'Ground', 81, 90, 0, 0, true, 737),
    new Rate('Antofagasta', 'Ground', 91, 100, 0, 0, true, 723),
    new Rate('Antofagasta', 'Ground', 101, 200, 0, 0, true, 580),
    new Rate('Antofagasta', 'Ground', 201, 500, 0, 0, true, 504),
    new Rate('Antofagasta', 'Ground', 501, Number.POSITIVE_INFINITY, 0, 0, true, 465),

    new Rate('Arica', 'Ground', 0, 5, 0, 0, false, 23116),
    new Rate('Arica', 'Ground', 6, 10, 0, 0, true, 2805),
    new Rate('Arica', 'Ground', 11, 20, 0, 0, true, 1999),
    new Rate('Arica', 'Ground', 21, 30, 0, 0, true, 1561),
    new Rate('Arica', 'Ground', 31, 40, 0, 0, true, 1333),
    new Rate('Arica', 'Ground', 41, 50, 0, 0, true, 1033),
    new Rate('Arica', 'Ground', 51, 60, 0, 0, true, 973),
    new Rate('Arica', 'Ground', 61, 70, 0, 0, true, 902),
    new Rate('Arica', 'Ground', 71, 80, 0, 0, true, 875),
    new Rate('Arica', 'Ground', 81, 90, 0, 0, true, 841),
    new Rate('Arica', 'Ground', 91, 100, 0, 0, true, 815),
    new Rate('Arica', 'Ground', 101, 200, 0, 0, true, 723),
    new Rate('Arica', 'Ground', 201, 500, 0, 0, true, 600),
    new Rate('Arica', 'Ground', 501, Number.POSITIVE_INFINITY, 0, 0, true, 561),

    new Rate('Calama', 'Ground', 0, 5, 0, 0, false, 22095),
    new Rate('Calama', 'Ground', 6, 10, 0, 0, true, 2635),
    new Rate('Calama', 'Ground', 11, 20, 0, 0, true, 1859),
    new Rate('Calama', 'Ground', 21, 30, 0, 0, true, 1433),
    new Rate('Calama', 'Ground', 31, 40, 0, 0, true, 1220),
    new Rate('Calama', 'Ground', 41, 50, 0, 0, true, 946),
    new Rate('Calama', 'Ground', 51, 60, 0, 0, true, 889),
    new Rate('Calama', 'Ground', 61, 70, 0, 0, true, 836),
    new Rate('Calama', 'Ground', 71, 80, 0, 0, true, 800),
    new Rate('Calama', 'Ground', 81, 90, 0, 0, true, 770),
    new Rate('Calama', 'Ground', 91, 100, 0, 0, true, 742),
    new Rate('Calama', 'Ground', 101, 200, 0, 0, true, 616),
    new Rate('Calama', 'Ground', 201, 500, 0, 0, true, 539),
    new Rate('Calama', 'Ground', 501, Number.POSITIVE_INFINITY, 0, 0, true, 500),

    new Rate('Chillan', 'Ground', 0, 5, 0, 0, false, 10980),
    new Rate('Chillan', 'Ground', 6, 10, 0, 0, true, 1868),
    new Rate('Chillan', 'Ground', 11, 20, 0, 0, true, 1053),
    new Rate('Chillan', 'Ground', 21, 30, 0, 0, true, 788),
    new Rate('Chillan', 'Ground', 31, 40, 0, 0, true, 732),
    new Rate('Chillan', 'Ground', 41, 50, 0, 0, true, 698),
    new Rate('Chillan', 'Ground', 51, 60, 0, 0, true, 658),
    new Rate('Chillan', 'Ground', 61, 70, 0, 0, true, 623),
    new Rate('Chillan', 'Ground', 71, 80, 0, 0, true, 605),
    new Rate('Chillan', 'Ground', 81, 90, 0, 0, true, 594),
    new Rate('Chillan', 'Ground', 91, 100, 0, 0, true, 575),
    new Rate('Chillan', 'Ground', 101, 200, 0, 0, true, 371),
    new Rate('Chillan', 'Ground', 201, 500, 0, 0, true, 352),
    new Rate('Chillan', 'Ground', 501, Number.POSITIVE_INFINITY, 0, 0, true, 340),

    new Rate('Concepcion', 'Ground', 0, 5, 0, 0, false, 11309),
    new Rate('Concepcion', 'Ground', 6, 10, 0, 0, true, 1924),
    new Rate('Concepcion', 'Ground', 11, 20, 0, 0, true, 1085),
    new Rate('Concepcion', 'Ground', 21, 30, 0, 0, true, 812),
    new Rate('Concepcion', 'Ground', 31, 40, 0, 0, true, 754),
    new Rate('Concepcion', 'Ground', 41, 50, 0, 0, true, 719),
    new Rate('Concepcion', 'Ground', 51, 60, 0, 0, true, 678),
    new Rate('Concepcion', 'Ground', 61, 70, 0, 0, true, 642),
    new Rate('Concepcion', 'Ground', 71, 80, 0, 0, true, 623),
    new Rate('Concepcion', 'Ground', 81, 90, 0, 0, true, 612),
    new Rate('Concepcion', 'Ground', 91, 100, 0, 0, true, 592),
    new Rate('Concepcion', 'Ground', 101, 200, 0, 0, true, 382),
    new Rate('Concepcion', 'Ground', 201, 500, 0, 0, true, 373),
    new Rate('Concepcion', 'Ground', 501, Number.POSITIVE_INFINITY, 0, 0, true, 364),

    new Rate('Copiapo', 'Ground', 0, 5, 0, 0, false, 12173),
    new Rate('Copiapo', 'Ground', 6, 10, 0, 0, true, 2043),
    new Rate('Copiapo', 'Ground', 11, 20, 0, 0, true, 1242),
    new Rate('Copiapo', 'Ground', 21, 30, 0, 0, true, 1047),
    new Rate('Copiapo', 'Ground', 31, 40, 0, 0, true, 903),
    new Rate('Copiapo', 'Ground', 41, 50, 0, 0, true, 821),
    new Rate('Copiapo', 'Ground', 51, 60, 0, 0, true, 774),
    new Rate('Copiapo', 'Ground', 61, 70, 0, 0, true, 745),
    new Rate('Copiapo', 'Ground', 71, 80, 0, 0, true, 724),
    new Rate('Copiapo', 'Ground', 81, 90, 0, 0, true, 708),
    new Rate('Copiapo', 'Ground', 91, 100, 0, 0, true, 695),
    new Rate('Copiapo', 'Ground', 101, 200, 0, 0, true, 565),
    new Rate('Copiapo', 'Ground', 201, 500, 0, 0, true, 488),
    new Rate('Copiapo', 'Ground', 501, Number.POSITIVE_INFINITY, 0, 0, true, 447),

    new Rate('Iquique', 'Ground', 0, 5, 0, 0, false, 22170),
    new Rate('Iquique', 'Ground', 6, 10, 0, 0, true, 2647),
    new Rate('Iquique', 'Ground', 11, 20, 0, 0, true, 1871),
    new Rate('Iquique', 'Ground', 21, 30, 0, 0, true, 1452),
    new Rate('Iquique', 'Ground', 31, 40, 0, 0, true, 1236),
    new Rate('Iquique', 'Ground', 41, 50, 0, 0, true, 958),
    new Rate('Iquique', 'Ground', 51, 60, 0, 0, true, 901),
    new Rate('Iquique', 'Ground', 61, 70, 0, 0, true, 874),
    new Rate('Iquique', 'Ground', 71, 80, 0, 0, true, 846),
    new Rate('Iquique', 'Ground', 81, 90, 0, 0, true, 826),
    new Rate('Iquique', 'Ground', 91, 100, 0, 0, true, 782),
    new Rate('Iquique', 'Ground', 101, 200, 0, 0, true, 623),
    new Rate('Iquique', 'Ground', 201, 500, 0, 0, true, 546),
    new Rate('Iquique', 'Ground', 501, Number.POSITIVE_INFINITY, 0, 0, true, 508),


    new Rate('La Serena', 'Ground', 0, 5, 0, 0, false, 12107),
    new Rate('La Serena', 'Ground', 6, 10, 0, 0, true, 2032),
    new Rate('La Serena', 'Ground', 11, 20, 0, 0, true, 1215),
    new Rate('La Serena', 'Ground', 21, 30, 0, 0, true, 1010),
    new Rate('La Serena', 'Ground', 31, 40, 0, 0, true, 863),
    new Rate('La Serena', 'Ground', 41, 50, 0, 0, true, 778),
    new Rate('La Serena', 'Ground', 51, 60, 0, 0, true, 724),
    new Rate('La Serena', 'Ground', 61, 70, 0, 0, true, 694),
    new Rate('La Serena', 'Ground', 71, 80, 0, 0, true, 673),
    new Rate('La Serena', 'Ground', 81, 90, 0, 0, true, 658),
    new Rate('La Serena', 'Ground', 91, 100, 0, 0, true, 645),
    new Rate('La Serena', 'Ground', 101, 200, 0, 0, true, 513),
    new Rate('La Serena', 'Ground', 201, 500, 0, 0, true, 454),
    new Rate('La Serena', 'Ground', 501, Number.POSITIVE_INFINITY, 0, 0, true, 406),

    new Rate('Coquimbo', 'Ground', 0, 5, 0, 0, false, 12107),
    new Rate('Coquimbo', 'Ground', 6, 10, 0, 0, true, 2032),
    new Rate('Coquimbo', 'Ground', 11, 20, 0, 0, true, 1215),
    new Rate('Coquimbo', 'Ground', 21, 30, 0, 0, true, 1010),
    new Rate('Coquimbo', 'Ground', 31, 40, 0, 0, true, 863),
    new Rate('Coquimbo', 'Ground', 41, 50, 0, 0, true, 778),
    new Rate('Coquimbo', 'Ground', 51, 60, 0, 0, true, 724),
    new Rate('Coquimbo', 'Ground', 61, 70, 0, 0, true, 694),
    new Rate('Coquimbo', 'Ground', 71, 80, 0, 0, true, 673),
    new Rate('Coquimbo', 'Ground', 81, 90, 0, 0, true, 658),
    new Rate('Coquimbo', 'Ground', 91, 100, 0, 0, true, 645),
    new Rate('Coquimbo', 'Ground', 101, 200, 0, 0, true, 513),
    new Rate('Coquimbo', 'Ground', 201, 500, 0, 0, true, 454),
    new Rate('Coquimbo', 'Ground', 501, Number.POSITIVE_INFINITY, 0, 0, true, 406),

    new Rate('La Union', 'Ground', 0, 5, 0, 0, false, 12730),
    new Rate('La Union', 'Ground', 6, 10, 0, 0, true, 2166),
    new Rate('La Union', 'Ground', 11, 20, 0, 0, true, 1316),
    new Rate('La Union', 'Ground', 21, 30, 0, 0, true, 1071),
    new Rate('La Union', 'Ground', 31, 40, 0, 0, true, 849),
    new Rate('La Union', 'Ground', 41, 50, 0, 0, true, 809),
    new Rate('La Union', 'Ground', 51, 60, 0, 0, true, 763),
    new Rate('La Union', 'Ground', 61, 70, 0, 0, true, 722),
    new Rate('La Union', 'Ground', 71, 80, 0, 0, true, 701),
    new Rate('La Union', 'Ground', 81, 90, 0, 0, true, 689),
    new Rate('La Union', 'Ground', 91, 100, 0, 0, true, 667),
    new Rate('La Union', 'Ground', 101, 200, 0, 0, true, 490),
    new Rate('La Union', 'Ground', 201, 500, 0, 0, true, 466),
    new Rate('La Union', 'Ground', 501, Number.POSITIVE_INFINITY, 0, 0, true, 434),

    new Rate('Los Angeles', 'Ground', 0, 5, 0, 0, false, 11649),
    new Rate('Los Angeles', 'Ground', 6, 10, 0, 0, true, 1982),
    new Rate('Los Angeles', 'Ground', 11, 20, 0, 0, true, 1117),
    new Rate('Los Angeles', 'Ground', 21, 30, 0, 0, true, 836),
    new Rate('Los Angeles', 'Ground', 31, 40, 0, 0, true, 777),
    new Rate('Los Angeles', 'Ground', 41, 50, 0, 0, true, 741),
    new Rate('Los Angeles', 'Ground', 51, 60, 0, 0, true, 698),
    new Rate('Los Angeles', 'Ground', 61, 70, 0, 0, true, 661),
    new Rate('Los Angeles', 'Ground', 71, 80, 0, 0, true, 642),
    new Rate('Los Angeles', 'Ground', 81, 90, 0, 0, true, 630),
    new Rate('Los Angeles', 'Ground', 91, 100, 0, 0, true, 610),
    new Rate('Los Angeles', 'Ground', 101, 200, 0, 0, true, 424),
    new Rate('Los Angeles', 'Ground', 201, 500, 0, 0, true, 403),
    new Rate('Los Angeles', 'Ground', 501, Number.POSITIVE_INFINITY, 0, 0, true, 375),

    new Rate('Osorno', 'Ground', 0, 5, 0, 0, false, 12604),
    new Rate('Osorno', 'Ground', 6, 10, 0, 0, true, 2144),
    new Rate('Osorno', 'Ground', 11, 20, 0, 0, true, 1303),
    new Rate('Osorno', 'Ground', 21, 30, 0, 0, true, 1061),
    new Rate('Osorno', 'Ground', 31, 40, 0, 0, true, 840),
    new Rate('Osorno', 'Ground', 41, 50, 0, 0, true, 801),
    new Rate('Osorno', 'Ground', 51, 60, 0, 0, true, 755),
    new Rate('Osorno', 'Ground', 61, 70, 0, 0, true, 715),
    new Rate('Osorno', 'Ground', 71, 80, 0, 0, true, 694),
    new Rate('Osorno', 'Ground', 81, 90, 0, 0, true, 682),
    new Rate('Osorno', 'Ground', 91, 100, 0, 0, true, 660),
    new Rate('Osorno', 'Ground', 101, 200, 0, 0, true, 485),
    new Rate('Osorno', 'Ground', 201, 500, 0, 0, true, 462),
    new Rate('Osorno', 'Ground', 501, Number.POSITIVE_INFINITY, 0, 0, true, 429),

    new Rate('Puerto Montt', 'Ground', 0, 5, 0, 0, false, 13112),
    new Rate('Puerto Montt', 'Ground', 6, 10, 0, 0, true, 2231),
    new Rate('Puerto Montt', 'Ground', 11, 20, 0, 0, true, 1356),
    new Rate('Puerto Montt', 'Ground', 21, 30, 0, 0, true, 1104),
    new Rate('Puerto Montt', 'Ground', 31, 40, 0, 0, true, 874),
    new Rate('Puerto Montt', 'Ground', 41, 50, 0, 0, true, 834),
    new Rate('Puerto Montt', 'Ground', 51, 60, 0, 0, true, 786),
    new Rate('Puerto Montt', 'Ground', 61, 70, 0, 0, true, 744),
    new Rate('Puerto Montt', 'Ground', 71, 80, 0, 0, true, 722),
    new Rate('Puerto Montt', 'Ground', 81, 90, 0, 0, true, 709),
    new Rate('Puerto Montt', 'Ground', 91, 100, 0, 0, true, 687),
    new Rate('Puerto Montt', 'Ground', 101, 200, 0, 0, true, 505),
    new Rate('Puerto Montt', 'Ground', 201, 500, 0, 0, true, 480),
    new Rate('Puerto Montt', 'Ground', 501, Number.POSITIVE_INFINITY, 0, 0, true, 447),

    new Rate('Rancagua', 'Ground', 0, 5, 0, 0, false, 10654),
    new Rate('Rancagua', 'Ground', 6, 10, 0, 0, true, 1813),
    new Rate('Rancagua', 'Ground', 11, 20, 0, 0, true, 1022),
    new Rate('Rancagua', 'Ground', 21, 30, 0, 0, true, 765),
    new Rate('Rancagua', 'Ground', 31, 40, 0, 0, true, 710),
    new Rate('Rancagua', 'Ground', 41, 50, 0, 0, true, 677),
    new Rate('Rancagua', 'Ground', 51, 60, 0, 0, true, 638),
    new Rate('Rancagua', 'Ground', 61, 70, 0, 0, true, 604),
    new Rate('Rancagua', 'Ground', 71, 80, 0, 0, true, 587),
    new Rate('Rancagua', 'Ground', 81, 90, 0, 0, true, 576),
    new Rate('Rancagua', 'Ground', 91, 100, 0, 0, true, 558),
    new Rate('Rancagua', 'Ground', 101, 200, 0, 0, true, 360),
    new Rate('Rancagua', 'Ground', 201, 500, 0, 0, true, 342),
    new Rate('Rancagua', 'Ground', 501, Number.POSITIVE_INFINITY, 0, 0, true, 330),

    new Rate('San Fernando', 'Ground', 0, 5, 0, 0, false, 10761),
    new Rate('San Fernando', 'Ground', 6, 10, 0, 0, true, 1831),
    new Rate('San Fernando', 'Ground', 11, 20, 0, 0, true, 1032),
    new Rate('San Fernando', 'Ground', 21, 30, 0, 0, true, 772),
    new Rate('San Fernando', 'Ground', 31, 40, 0, 0, true, 717),
    new Rate('San Fernando', 'Ground', 41, 50, 0, 0, true, 684),
    new Rate('San Fernando', 'Ground', 51, 60, 0, 0, true, 645),
    new Rate('San Fernando', 'Ground', 61, 70, 0, 0, true, 611),
    new Rate('San Fernando', 'Ground', 71, 80, 0, 0, true, 593),
    new Rate('San Fernando', 'Ground', 81, 90, 0, 0, true, 582),
    new Rate('San Fernando', 'Ground', 91, 100, 0, 0, true, 564),
    new Rate('San Fernando', 'Ground', 101, 200, 0, 0, true, 363),
    new Rate('San Fernando', 'Ground', 201, 500, 0, 0, true, 345),
    new Rate('San Fernando', 'Ground', 501, Number.POSITIVE_INFINITY, 0, 0, true, 333),

    new Rate('Santa Cruz', 'Ground', 0, 5, 0, 0, false, 10761),
    new Rate('Santa Cruz', 'Ground', 6, 10, 0, 0, true, 1831),
    new Rate('Santa Cruz', 'Ground', 11, 20, 0, 0, true, 1032),
    new Rate('Santa Cruz', 'Ground', 21, 30, 0, 0, true, 772),
    new Rate('Santa Cruz', 'Ground', 31, 40, 0, 0, true, 717),
    new Rate('Santa Cruz', 'Ground', 41, 50, 0, 0, true, 684),
    new Rate('Santa Cruz', 'Ground', 51, 60, 0, 0, true, 645),
    new Rate('Santa Cruz', 'Ground', 61, 70, 0, 0, true, 611),
    new Rate('Santa Cruz', 'Ground', 71, 80, 0, 0, true, 593),
    new Rate('Santa Cruz', 'Ground', 81, 90, 0, 0, true, 582),
    new Rate('Santa Cruz', 'Ground', 91, 100, 0, 0, true, 564),
    new Rate('Santa Cruz', 'Ground', 101, 200, 0, 0, true, 363),
    new Rate('Santa Cruz', 'Ground', 201, 500, 0, 0, true, 345),
    new Rate('Santa Cruz', 'Ground', 501, Number.POSITIVE_INFINITY, 0, 0, true, 333),

    new Rate('Talca', 'Ground', 0, 5, 0, 0, false, 10870),
    new Rate('Talca', 'Ground', 6, 10, 0, 0, true, 1849),
    new Rate('Talca', 'Ground', 11, 20, 0, 0, true, 1042),
    new Rate('Talca', 'Ground', 21, 30, 0, 0, true, 780),
    new Rate('Talca', 'Ground', 31, 40, 0, 0, true, 725),
    new Rate('Talca', 'Ground', 41, 50, 0, 0, true, 691),
    new Rate('Talca', 'Ground', 51, 60, 0, 0, true, 651),
    new Rate('Talca', 'Ground', 61, 70, 0, 0, true, 617),
    new Rate('Talca', 'Ground', 71, 80, 0, 0, true, 599),
    new Rate('Talca', 'Ground', 81, 90, 0, 0, true, 588),
    new Rate('Talca', 'Ground', 91, 100, 0, 0, true, 569),
    new Rate('Talca', 'Ground', 101, 200, 0, 0, true, 367),
    new Rate('Talca', 'Ground', 201, 500, 0, 0, true, 348),
    new Rate('Talca', 'Ground', 501, Number.POSITIVE_INFINITY, 0, 0, true, 337),

    new Rate('Temuco', 'Ground', 0, 5, 0, 0, false, 11882),
    new Rate('Temuco', 'Ground', 6, 10, 0, 0, true, 2021),
    new Rate('Temuco', 'Ground', 11, 20, 0, 0, true, 1139),
    new Rate('Temuco', 'Ground', 21, 30, 0, 0, true, 853),
    new Rate('Temuco', 'Ground', 31, 40, 0, 0, true, 792),
    new Rate('Temuco', 'Ground', 41, 50, 0, 0, true, 755),
    new Rate('Temuco', 'Ground', 51, 60, 0, 0, true, 712),
    new Rate('Temuco', 'Ground', 61, 70, 0, 0, true, 674),
    new Rate('Temuco', 'Ground', 71, 80, 0, 0, true, 655),
    new Rate('Temuco', 'Ground', 81, 90, 0, 0, true, 643),
    new Rate('Temuco', 'Ground', 91, 100, 0, 0, true, 622),
    new Rate('Temuco', 'Ground', 101, 200, 0, 0, true, 458),
    new Rate('Temuco', 'Ground', 201, 500, 0, 0, true, 435),
    new Rate('Temuco', 'Ground', 501, Number.POSITIVE_INFINITY, 0, 0, true, 405),

    new Rate('Valdivia', 'Ground', 0, 5, 0, 0, false, 12119),
    new Rate('Valdivia', 'Ground', 6, 10, 0, 0, true, 2062),
    new Rate('Valdivia', 'Ground', 11, 20, 0, 0, true, 1253),
    new Rate('Valdivia', 'Ground', 21, 30, 0, 0, true, 1020),
    new Rate('Valdivia', 'Ground', 31, 40, 0, 0, true, 808),
    new Rate('Valdivia', 'Ground', 41, 50, 0, 0, true, 770),
    new Rate('Valdivia', 'Ground', 51, 60, 0, 0, true, 726),
    new Rate('Valdivia', 'Ground', 61, 70, 0, 0, true, 688),
    new Rate('Valdivia', 'Ground', 71, 80, 0, 0, true, 668),
    new Rate('Valdivia', 'Ground', 81, 90, 0, 0, true, 656),
    new Rate('Valdivia', 'Ground', 91, 100, 0, 0, true, 635),
    new Rate('Valdivia', 'Ground', 101, 200, 0, 0, true, 467),
    new Rate('Valdivia', 'Ground', 201, 500, 0, 0, true, 444),
    new Rate('Valdivia', 'Ground', 501, Number.POSITIVE_INFINITY, 0, 0, true, 413),

    new Rate('Viña del Mar', 'Ground', 0, 5, 0, 0, false, 11913),
    new Rate('Viña del Mar', 'Ground', 6, 10, 0, 0, true, 2001),
    new Rate('Viña del Mar', 'Ground', 11, 20, 0, 0, true, 1173),
    new Rate('Viña del Mar', 'Ground', 21, 30, 0, 0, true, 962),
    new Rate('Viña del Mar', 'Ground', 31, 40, 0, 0, true, 809),
    new Rate('Viña del Mar', 'Ground', 41, 50, 0, 0, true, 722),
    new Rate('Viña del Mar', 'Ground', 51, 60, 0, 0, true, 660),
    new Rate('Viña del Mar', 'Ground', 61, 70, 0, 0, true, 631),
    new Rate('Viña del Mar', 'Ground', 71, 80, 0, 0, true, 611),
    new Rate('Viña del Mar', 'Ground', 81, 90, 0, 0, true, 594),
    new Rate('Viña del Mar', 'Ground', 91, 100, 0, 0, true, 583),
    new Rate('Viña del Mar', 'Ground', 101, 200, 0, 0, true, 448),
    new Rate('Viña del Mar', 'Ground', 201, 500, 0, 0, true, 381),
    new Rate('Viña del Mar', 'Ground', 501, Number.POSITIVE_INFINITY, 0, 0, true, 336),

    new Rate('Valparaiso', 'Ground', 0, 5, 0, 0, false, 11913),
    new Rate('Valparaiso', 'Ground', 6, 10, 0, 0, true, 2001),
    new Rate('Valparaiso', 'Ground', 11, 20, 0, 0, true, 1173),
    new Rate('Valparaiso', 'Ground', 21, 30, 0, 0, true, 962),
    new Rate('Valparaiso', 'Ground', 31, 40, 0, 0, true, 809),
    new Rate('Valparaiso', 'Ground', 41, 50, 0, 0, true, 722),
    new Rate('Valparaiso', 'Ground', 51, 60, 0, 0, true, 660),
    new Rate('Valparaiso', 'Ground', 61, 70, 0, 0, true, 631),
    new Rate('Valparaiso', 'Ground', 71, 80, 0, 0, true, 611),
    new Rate('Valparaiso', 'Ground', 81, 90, 0, 0, true, 594),
    new Rate('Valparaiso', 'Ground', 91, 100, 0, 0, true, 583),
    new Rate('Valparaiso', 'Ground', 101, 200, 0, 0, true, 448),
    new Rate('Valparaiso', 'Ground', 201, 500, 0, 0, true, 381),
    new Rate('Valparaiso', 'Ground', 501, Number.POSITIVE_INFINITY, 0, 0, true, 336),
  
    new Rate('Punta Arenas', 'Ground', 0, 38, 0, 0, false, 19350),
    new Rate('Punta Arenas', 'Ground', 39, 66, 0, 0, false, 34076),
    new Rate('Punta Arenas', 'Ground', 67, 100, 0, 0, false, 51711),
    new Rate('Punta Arenas', 'Ground', 101, 120, 0, 0, false, 60958),
    new Rate('Punta Arenas', 'Ground', 121, Number.POSITIVE_INFINITY, 0, 0, true, 562),
]
// rates

export default rates;