import {Button, Modal, Form} from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { newItem } from '../store/actions/data'
function NewModal(props) {
    const [show, setShow] = useState(props.show);
  
    const [form, setForm] = useState({});
    const { city, transportType } = props;
    const dispatch = useDispatch();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const create = (e) => {
      e.preventDefault()
      if (form['city'] === undefined) { 
        form['city'] = city;
      }
      if (form['transport_type'] === undefined) { 
        form['transport_type'] = transportType;
      }
      dispatch(newItem(form))
    }
    const handleChange = (e) => {
      let newForm = form;
      newForm[e.target.name] = e.target.value;
      setForm(newForm)
    }

    const handleNumberChange = (e) => {
        let newForm = form;
        newForm[e.target.name] = parseInt(e.target.value);
        setForm(newForm)
      }
    const handleCheckChange = (e) => {
      let newForm = form;
      if (e.target.id === 'true')
       {
         newForm[e.target.name] = true;}
       else {
        newForm[e.target.name] = false;
       }
      setForm(newForm)
    }
    
    return (
      <>
        <Button variant="primary" size='xs' onClick={handleShow}>
          Nueva tarifa
        </Button>
  
        <Modal show={show} onHide={handleClose}>
        <Form onSubmit={create}>

        <Modal.Header closeButton>
          <Modal.Title>Nueva tarifa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formCity">
              <Form.Label>Ciudad</Form.Label>
              <Form.Control type="input" placeholder="Ciudad" name='city' onChange={handleChange} defaultValue={city}/>
          </Form.Group>
          <Form.Group controlId="formTransportType">
              <Form.Label>Tipo transporte</Form.Label>
              <Form.Control type="input" placeholder="Aereo, Terrestre, Maritimo" name='transport_type' onChange={handleChange} defaultValue={transportType}/>
          </Form.Group>

          <Form.Group controlId="formMinWeight">
              <Form.Label>Peso minimo</Form.Label>
              <Form.Control type="number" placeholder="0" min='0' name='min_weight' onChange={handleNumberChange}/>
          </Form.Group>
          <Form.Group controlId="formMaxWeight">
              <Form.Label>Peso maximo</Form.Label>
              <Form.Control type="number" placeholder="0" min='0' name='max_weight' onChange={handleNumberChange} />
          </Form.Group>
          <Form.Group controlId="formPrice">
              <Form.Label>Precio</Form.Label>
              <Form.Control type="number" placeholder="0" min='0' name='price' onChange={handleNumberChange} />
          </Form.Group>
          <Form.Group controlId="formMaxWeight" onChange={handleCheckChange}>
            <Form.Label>Tipo calculo</Form.Label>
            <Form.Check
              type={'radio'}
              id={'true'}
              name={`by_weight`}
              label='Por peso'
              defaultChecked={true}
              
            />
            <Form.Check
              type={'radio'}
              id={'false'}
              name={`by_weight`}
              label='Total'
              defaultChecked={false}
            />
          </Form.Group>
          <Form.Group controlId="formMaxWeight">
              <Form.Label>Tasa Embarque</Form.Label>
              <Form.Control type="integer" placeholder="0" min='0' name='boarding_cost' onChange={handleNumberChange} />
          </Form.Group>
          <Form.Group controlId="formInsurance">
              <Form.Label>Seguro</Form.Label>
              <Form.Control type="number" placeholder="0" min='0' name='insurance' onChange={handleNumberChange} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="primary" type='submit' onClick={handleClose}>
            Guardar
          </Button>
        </Modal.Footer>
        </Form>
        </Modal>
      </>
    );
  }
  export default NewModal;