import {Button, Modal, Form} from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import API from '../utils/api'
import { handleLogin } from '../store/actions/user';
import {userSignedIn} from '../utils/helpers'
import { useDispatch } from 'react-redux'

function LoginModal(props) {
    const [show, setShow] = useState(props.show);
    const [form, setForm] = useState({})
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const login = (e) => {
      e.preventDefault()
      dispatch(handleLogin(form))
      navigate('/')

  }
  const handleChange = (e) => {
    let newForm = form;
    newForm[e.target.name] = e.target.value;
    setForm(newForm)

}
  
    return (
      <>
      {userSignedIn() ? <Button variant="primary" type="submit" as={Link} to={'/edit'}>Editar Tarifas</Button> : 
        <Button variant="primary" onClick={handleShow}>
          Login
        </Button>}
  
        <Modal show={show} onHide={handleClose}>
            <Form onSubmit={login}>

              <Modal.Header closeButton>
                <Modal.Title>Login</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" name='email' onChange={handleChange}/>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" name='password' onChange={handleChange} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" type='submit' onClick={handleClose}>
                  Login
                </Button>
              </Modal.Footer>
            </Form>
          
        </Modal>
      </>
    );
  }
  export default LoginModal;