import { useState } from 'react'

import { Form, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { onlyUnique } from '../utils/helpers'
// import trips from '../data/trips'

const SearchForm = (props) => {
    const { trips } = useSelector(state => state.data);
    const handleSubmit = (e) => {
        e.preventDefault();
        props.searchParams(form)
    }

    const handleTransportType = (e) => {
        setCities(trips.filter(trip => trip.transport_type === e.target.id))
        const newForm = form;
        newForm.transportType = e.target.id;
        setForm(newForm)
    }
    const handleChange = (e) => {
        const newForm = form;
        newForm[e.target.id] = e.target.value;
        setForm(newForm)
    }
    const [form, setForm] = useState({
        transportType: '',
        city: '',
        weight: 0,
        packages: 0,
        dimensions: '',

    });
    const [cities, setCities] = useState([]);
    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" onChange={handleTransportType}>
                {trips.map(i => i.transport_type).filter(onlyUnique).map((transport, idx) => {
                    return (
                        <Form.Check
                            key={idx}
                            type={'radio'}
                            id={transport}
                            name={`transportType`}
                            label={transport}
                        />)})}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label htmlFor="inputCity">Ciudad</Form.Label>
                <Form.Select aria-label="Default select example" id='city' onChange={handleChange}>
                    <option>Destino</option>
                    {cities.sort((a, b) => a.city.localeCompare(b.city)).map((trip, idx) => <option key={idx} value={trip.city}>{trip.city}</option>)}
                </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label htmlFor="formWeight">Peso</Form.Label>
                <Form.Control
                    type="number"
                    id="weight"
                    onChange={handleChange}
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label htmlFor="formWeight">Bultos</Form.Label>
                <Form.Control
                    type="number"
                    id="packages"
                    onChange={handleChange}
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label htmlFor="formWeight">Dimensiones</Form.Label>
                <Form.Control
                    type="text"
                    id="dimensions"
                    placeholder='alto x ancho x largo'
                    onChange={handleChange}
                />
            </Form.Group>
        
            <Button variant="primary" type="submit">
            Calcular
            </Button>
        </Form>
    )
}

export default SearchForm;