import { useState } from 'react'
import {InputGroup, FormControl, Container, Row, Card, Col, CloseButton, Button, ButtonGroup, Table} from 'react-bootstrap'
// import trips from '../data/trips'
// import prices from '../data/prices'
import EditModal from '../components/EditModal'
import NewModal from '../components/NewModal'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { onlyUnique } from '../utils/helpers'
import { deleteItem } from '../store/actions/data'

function Edit() {

    const {prices, trips} = useSelector(state => state.data);
    const [searchValue, setSearchValue] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedTransport, setSelectedTransport] = useState('');
    const dispatch = useDispatch();
    const handleSelectCity = (e) => {
        setSelectedCity(e.target.innerHTML)
    }
    const handleRemoveCity = () => {
        setSelectedCity('')
        setSelectedTransport('')
    }
    const handleSelectTransport= (e) => {
        setSelectedTransport(e.target.innerHTML )
    }
    const citySelected = () => {
        return selectedCity !== ''
    }
    const transportSelected = () => {
        return selectedTransport !== ''
    }
    const handleDelete = (e) => {
        
        const confirmDiag = window.confirm('¿Seguro que quieres borrar esta tarifa?')

        if (confirmDiag) {
            dispatch(deleteItem(e.target.id))
        }
    }
  return (
    <Container>
        <Row style={{marginTop: '10px'}}>
            <InputGroup className="mb-3">

                <Button as={Link} to='/'>Atras</Button>
                <InputGroup.Text id="inputGroup-sizing-default">Buscar</InputGroup.Text>
                <FormControl
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setSearchValue(e.target.value)}
                />

            <NewModal city={selectedCity} transportType={selectedTransport}/>
            </InputGroup>
        </Row>
        {citySelected() ? <Row>
            <Col>
                <Card style={{ width: '18rem', margin: '10px' }}>
                    <Card.Header>{selectedCity}<CloseButton style={{float: 'right'}} onClick={handleRemoveCity}/></Card.Header>
                </Card>
            </Col>
        </Row> : <Row>
            {trips.map(i => i.city).filter(onlyUnique).filter(city => city.toLowerCase().includes(searchValue.toLowerCase())).sort((a, b) => a.localeCompare(b)).map((city, idx) => {
                return (
                    <Col key={idx}>
                        <Card style={{ width: '18rem', margin: '10px' }} onClick={handleSelectCity} name={city}>
                            <Card.Header>{city}</Card.Header>
                        </Card>
                    </Col>
                )
            })}
        </Row>}

        {citySelected() && <Row>
            <ButtonGroup>
                
                {trips.map(i => i.transport_type).filter(onlyUnique).map((trip, idx) => {
                    return (
                        <Button  key={idx} variant={selectedTransport == trip ? 'primary' : 'secondary'} onClick={handleSelectTransport} name={trip} active={selectedTransport == trip ? true : false}>{trip}</Button>
                    )
                })}
            </ButtonGroup>
        </Row>}
        { selectedCity && selectedTransport && <Row style={{marginTop: '20px'}}>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Peso min</th>
                        <th>Peso max</th>
                        <th>Precio</th>
                        <th>Tipo Pago</th>
                        <th>Embarque</th>
                        <th>Seguro</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {citySelected() ? prices
                        .filter(p => p.city == selectedCity)
                        .filter(p => p.transport_type == selectedTransport)
                        .sort((a, b) => a.min_weight - b.min_weight)
                        .map((trip, idx) => (
                            <tr key={idx}>
                                <td>{trip.min_weight < 0 ? 0 : trip.min_weight}</td>
                                <td>{trip.max_weight == 0 ? '++' : trip.max_weight}</td>
                                <td>{trip.price.toLocaleString('es-CL', {style: 'currency', currency: 'CLP'})}</td>
                                <td>{trip.by_weight ? 'por kg' : 'Total'}</td>
                                <td>{trip.boarding_cost.toLocaleString('es-CL', {style: 'currency', currency: 'CLP'})}</td>
                                <td>{(trip.insurance/100).toLocaleString("es", {style: "percent", minimumFractionDigits: 2})}</td>
                                <td><EditModal trip={trip}/>{' '}<Button variant='danger' size='xs' onClick={handleDelete} id={trip.id}>Borrar</Button></td>
                            </tr>
                        )) : null}
                </tbody>
            </Table>
        </Row>}

    </Container>

  )
}

export default Edit