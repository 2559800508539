import React from 'react';
import API from '../../utils/api';

import * as actionTypes from './actionTypes';

export const fetchDataSuccess = (data) => {
  return {
      type: actionTypes.FETCH_DATA_SUCCESS,
      data
  }
}
export const fetchDataFail = (error) => {
  return {
      type: actionTypes.FETCH_DATA_FAILED,
      error
  }
}

export const fetchDataStart = () => {
  return {
      type: actionTypes.FETCH_DATA_START
  }
}

export const createRate = (item) => {
  return {
      type: actionTypes.CREATE_RATE,
      item
  }
}
export const updateRate = (item) => {
  return {
      type: actionTypes.UPDATE_RATE,
      item
  }
}
export const deleteRate = (id) => {
  return {
      type: actionTypes.DELETE_RATE,
      id
  }
}

export const fetchData = () => {
  return dispatch => {
      dispatch(fetchDataStart());
      API.get(`api/v1/ql/rates`)
      .then(res => {
          const fetchedData = res.data;
          dispatch(fetchDataSuccess(fetchedData))
      })
      .catch(err => {
          dispatch(fetchDataFail(err))
      });
  }
};

export const newItem = (item) => {
  return dispatch => {
      API.post(`api/v1/ql/rates`, item)
      .then(res => {
          console.log(res.data)
          console.log(item)
          dispatch(createRate(res.data))
      })
      .catch(err => {
        
      });
  }
};

export const updateItem = (item, id) => {
  return dispatch => {
    console.log(item, id)
      API.put(`api/v1/ql/rates/${id}`, item)
      .then(res => {
          dispatch(updateRate(res.data))
      })
      .catch(err => {
        
      });
  }
};


export const deleteItem = (id) => {
  return dispatch => {
      API.delete(`api/v1/ql/rates/${id}`)
      .then(res => {
          dispatch(deleteRate(id))
      })
      .catch(err => {
        
      });
  }
};