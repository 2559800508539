import * as actionTypes from '../actions/actionTypes';

import { updatedObject } from '../../utils';

const initialState = {
    loading: false,
    fetched: false,
    prices: [],
    trips: [],
}
const data = (state = initialState, action) => {
    let newItems = [];
    switch (action.type) {
        case actionTypes.FETCH_DATA_START:
            return updatedObject(state, { loading: true });
        case actionTypes.FETCH_DATA_SUCCESS:
            return updatedObject(state, { trips: action.data.trips, prices: action.data.prices, loading: false});
        case actionTypes.FETCH_DATA_FAILED:
            return updatedObject(state, { loading: true});
        case actionTypes.CREATE_RATE:
            return updatedObject(state, { prices: [action.item, ...state.prices]});
        case actionTypes.UPDATE_RATE:
            newItems = state.prices.map(item => {
                if (item.id === parseInt(action.item.id)) {
                    return action.item
                }
                return item
            })
            return updatedObject(state, { prices: newItems});
        case actionTypes.DELETE_RATE:
            newItems = state.prices.filter(item => item.id !== parseInt(action.id))
            return updatedObject(state, { prices: newItems });
      default:
        return state;
    }
    };
  
  export default data;