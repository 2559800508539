import { useState } from 'react'

import SearchForm from '../components/SearchForm';
import prices from '../data/prices';
import API from '../utils/api'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loading from '../components/Loading';

import { Container, Col, Row, Button } from 'react-bootstrap'
import LoginModal from '../components/LoginModal';
import { userSignedIn } from '../utils/helpers';

export default function RateFinder() {

  const { prices, trips, loading } = useSelector(state => state.data);
    const searchParams = (formValues) => {
        const dimensionWeight = (size) => {
          var countAngles = (size.match(/x/g) || []).length;
          if (countAngles === 2) {
            const newDimWeight = size.split('x').reduce((partialSum, a) => parseInt(partialSum) * parseInt(a), 0);

            return parseInt(newDimWeight/4000);
          } else {
            return 0;
          }
        }
        let newWeight = parseInt(formValues.weight);
        if (dimensionWeight(formValues.dimensions) > newWeight) {
          newWeight = dimensionWeight(formValues.dimensions);
          setWeightType('dimensions')
        }
        const checkMaxWeight = (weight) => {
          if (weight === 0) {
            return Infinity;
          } else {
            return weight;
          }
        }

        setPackageWeight(newWeight)
        const newPrice = prices.filter(rate =>  rate.city === formValues.city && 
                                                rate.transport_type === formValues.transportType &&
                                                rate.min_weight <= newWeight &&
                                                checkMaxWeight(rate.max_weight) >= newWeight 
                                                )
        setRate(newPrice)
    
      }
    
      const [rate, setRate] = useState([])
      const [packageWeight, setPackageWeight] = useState(0)
      const [weightType, setWeightType] = useState('weight')
      const formatCurrency = (value) => value.toLocaleString('es-CL', {style: 'currency', currency: 'CLP',});
    
      if (loading) return (<Container>
            <Row style={{textAlign: 'center'}}>
              <h1>Calculador de tarifas</h1>
            </Row>
            <Row style={{textAlign: 'center'}}>
            <Loading />
            </Row>
          </Container>)
      return (
        <Container>
          <Row style={{textAlign: 'center'}}>
            <h1>Calculador de tarifas</h1>
          </Row>
          <Row>
            <Col>
              <SearchForm searchParams={searchParams}/>
            </Col>
            <Col>
              <div style={{margin: '20%'}}>
                { packageWeight && rate.length === 0 ? <Row style={{textAlign: 'center'}}><h3>No hay tarifa para ese peso</h3></Row> : null}
                {rate && rate.map((rate, idx) => {
                  return (
                    <div key={idx}>
                      <Row style={{textAlign: 'center'}}><h3>Costo de Envio</h3></Row>
                      <Row style={{textAlign: 'center'}}>
                        <p>Destino: <b>{rate.city}</b></p>
                        <p>Peso Calculo: <b>{parseInt(packageWeight).toLocaleString('es-CL')}kg</b></p>
                        {rate.insurance > 0 ? <p>Seguro: <b>{rate.insurance}%</b></p> : null}
                        {rate.boarding_cost > 0 ? <p>Costo Embarque: <b>{formatCurrency(rate.boarding_cost)}</b></p> : null}
                        {rate.by_weight ? <p>Precio por kg: <b>{formatCurrency(rate.price)}</b></p> : null}
                      </Row>
                      <Row style={{textAlign: 'center'}}>
                        <h1><b>{rate.by_weight ? formatCurrency(parseInt(rate.price)*parseInt(packageWeight)+(parseInt(rate.price)*parseInt(packageWeight)*rate.insurance/100)+parseInt(rate.boarding_cost)) : formatCurrency(parseInt(rate.price)+(parseInt(rate.price)*parseInt(packageWeight)*rate.insurance/100)+parseInt(rate.boarding_cost))}</b></h1>
                      </Row>
                    </div>
                  )
                })}
              </div>
            </Col>
          </Row>
          <Row style={{marginTop: 10}}>
            <Col>
                <LoginModal />
            </Col>
          </Row>
        </Container>
      );
}
