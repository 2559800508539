import * as actionTypes from '../actions/actionTypes';
import { updatedObject } from '../../utils';

const initialState = {
  loggedIn: false
}
const user = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.LOG_IN:
        return updatedObject(state, { loggedIn: true });
      case actionTypes.LOG_OUT:
        return updatedObject(state, { loggedIn: false });
      default:
        return state;
    }
  };
  
  export default user;